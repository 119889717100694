.calculator {
  position: relative;
  padding: 30px 0;
  width: 100%;

  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  z-index: 3;
}

.calculator__id-link {
  position: absolute;
  top: -70px;
}

.calculator__wrapper {
  display: block;

  &--none {
    display: none;
  }
}

//.calculator__select {
//  max-width: 368px;
//  width: auto;
//}

.calculator__wrapper-second {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--show {
    display: flex;
  }

  p {
    margin: 0;
    text-align: center;
  }

  span {
    color: #F0B90B;
  }

  a {
    color: #F0B90B;
    text-decoration: none;
  }

  .calculator__logo-img {
    margin-bottom: 10px;
    width: 260px;
  }

  .calculator__copy-btn {
    margin-left: 20px;
    padding: 0;

    background: none;
    border: none;

    &:hover {
      img {
        filter: brightness(0) saturate(100%) invert(70%) sepia(95%) saturate(1874%) hue-rotate(4deg) brightness(107%) contrast(91%);
      }
    }
  }
}

.calculator__top {
  display: flex;
  justify-content: center;

  margin: 0 auto;
  width: 85%;
}

.calculator__give,
.calculator__get {
  display: flex;
  flex-direction: column;

  width: calc(50% - 120px);

  p {
    margin: 0 0 15px 0;
    font-weight: 600;
  }

  .input {
    max-width: 368px;
    width: auto;
  }
}

.calculator__get {
  p {
    text-align: right;
  }
}

.calculator__logo {
  margin: 0 40px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    margin: 0 auto;
  }

  p {
    margin: 10px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}

.calculator__bottom {
  display: flex;
  align-items: center;

  .input {
    margin: 0 20px 0 0;
    width: 25%;
  }

  .btn-main {
    margin: 0;

    font-size: 16px;

    background: linear-gradient(180deg, #F0B90B 0%, #F0A20B 100%);
    transition: 0.3s;

    &:hover {
      background: linear-gradient(180deg, #F0A20B 0%, #F0A20B 100%);
    }
  }
}

.calculator__input-user {
  margin: 0 auto 20px;
  width: 40%;
}

@media (max-width: 1024px) {
  .calculator__input-user {
    margin: 0 auto 20px;
    width: 50%;
  }

  .calculator__top {
    width: 100%;
  }

  .calculator__give,
  .calculator__get {
    width: calc(50% - 80px);
  }

  .calculator__logo {
    margin: 0 15px;
  }

  .calculator__bottom {
    justify-content: space-between;
    flex-wrap: wrap;

    .input {
      margin: 0 0 20px 0;
      width: 48%;
    }

    .btn-main {
      margin: 0 0 20px 0;
      width: 48%;
    }
  }
}

@media (max-width: 700px) {
  .calculator__input-user {
    margin: 0 auto 20px;
    width: 100%;
  }

  .calculator {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .calculator__top {
    justify-content: space-between;
  }

  .calculator__give,
  .calculator__get {
    width: 48%;
  }

  .calculator__logo {
    display: none;
  }
}

@media (max-width: 580px) {
  .calculator__top {
    flex-direction: column;
  }

  .calculator__give,
  .calculator__get {
    width: 100%;

    p {
      text-align: left;
    }

    .input {
      max-width: 100%;
      width: 100%;
    }
  }

  .calculator__bottom {
    margin-top: 20px;
    flex-direction: column;

    .input {
      margin: 0 0 20px 0;
      width: 100%;
    }

    .btn-main {
      margin: 0;
      width: 100%;
    }
  }
}
