.header {
  display: block;
  font-weight: 400;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 70px;

  background-color: #181A1F;
  transition: 0.3s;

  z-index: 50;

  .animate-svg {
    margin-right: 10px;
  }

  &.new-color {
    background: #181A1F;
  }

  .header__wrapper {
    box-sizing: border-box;
    height: 100%;
  }

  .main-nav {
    height: 100%;

    display: flex;
    justify-content: space-between;

    font-size: 18px;
    line-height: 22px;
  }

  .main-nav__menu-btn {
    display: none;
  }

  .main-nav__container {
    height: 100%;

    flex-grow: 1;
  }

  .main-nav__wrapper {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-nav__logo {
    display: flex;
    align-items: center;

    img {
      margin-top: 5px;
      height: 60%;
    }
  }

  .main-nav__site {
    margin: 0 0 0 100px;
    padding: 0;
    list-style: none;

    height: 100%;

    display: flex;

    & > li {
      margin: 0 25px;

      display: flex;
      align-items: center;

      & a {
        color: inherit;
        text-decoration: none;

        transition: 0.2s;

        &:hover,
        &:focus {
          color: #F0B90B;
        }
      }
    }
  }

  .main-nav__user {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;

    display: flex;

    & li {
      & button, a {
        color: inherit;
        border: none;
        background: transparent;
        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 38px;
        padding: 8px 32px;
        max-width: 320px;

        font-weight: 400;
        font-size: 18px;
        line-height: 22px;

        border: 1px solid #FFFFFF;
        border-radius: 50px;

        transition: 0.2s;

        &:hover,
        &:focus {
          color: #F0B90B;
          border: 1px solid #F0B90B;
        }
      }
    }
  }
}

.main-nav__lang-li {
  padding: 0 20px;
  position: relative;

  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }

  & > span {
    display: inline-block;

    color: #F0A20B;

    cursor: pointer;

    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;

    &::after {
      content: '';
      display: inline-block;

      margin: 0 0 1px 10px;
      width: 7px;
      height: 7px;

      border-right: 2px solid #F0A20B;
      border-bottom: 2px solid #F0A20B;

      transform: rotate(-45deg);
      transition: 0.3s;
    }
  }

  &.show {
    & > span {
      &::after {
        margin: 0 0 3px 10px;
        transform: rotate(45deg);
      }
    }
  }
}

.main-nav__lang-list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: none;

  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;

  background: #181A1F;
  border-radius: 0px 0px 10px 10px;

  overflow: hidden;

  &.show {
    display: block;
  }

  & > li {
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.2s;

    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;

    &:hover,
    &:focus,
    &:active {
      outline: none !important;
      -webkit-tap-highlight-color: transparent !important;
    }

    &::after {
      content: '';

      margin: -2px 0 0 10px;
      width: 7px;
      height: 7px;

      border-right: 2px solid #FFFFFF;
      border-bottom: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      transition: 0.2s;
    }

    &:hover {
      color: #F0A20B;

      &::after {
        border-right: 2px solid #F0A20B;
        border-bottom: 2px solid #F0A20B;
      }
    }
  }
}

@media (max-width: 1250px) {
  .header {
    .main-nav__site {
      margin: 0 0 0 40px;
      & > li {
        margin: 0 15px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .header {
    height: 60px;

    .header__wrapper {
      margin: 0;
      padding: 0 30px;
      width: 100%;
    }

    .main-nav__logo {
      font-size: 30px;
      line-height: 36px;
    }

    .main-nav__site {
      font-size: 16px;
      line-height: 18px;
    }

    .main-nav__user {
      margin: 0 0 0 30px;

      li {
        &:nth-child(2) {
          margin-left: 20px;
        }

        button, a {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .header {
    height: 50px;

    .main-nav {
      display: flex;
      //justify-content: flex-end;
      align-items: center;
    }

    .main-nav__menu-btn {
      display: block;
      padding: 6px 0 0 0;
      width: 30px;

      & > div > span {
        width: 30px;
        height: 3px;

        &::after {
          bottom: -8px;
          width: 22px;
          height: 3px;
        }

        &::before {
          top: -8px;
          width: 26px;
          height: 3px;
        }
      }

      &.show {
        & > div > span {
          &::after {
            bottom: 0px;
            width: 30px;
            height: 3px;
          }
        }
      }
    }

    .main-nav__container {
      position: fixed;
      top: 50px;
      right: 0;
      width: 100vw;
      height: 0;

      box-sizing: border-box;
      //padding: 20% 0;

      background: #181A1F;
      transition: 0.5s;
      overflow: hidden;

      overflow-x: hidden;
      z-index: -1;


      &.show {
        height: calc(100vh - 50px);
        //padding-bottom: 40%;
        transition: 0.5s;
      }
    }

    .main-nav__wrapper {
      margin: 0 auto;
      width: 50%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .main-nav__logo {
      //position: absolute;
      //top: -40px;
      //left: 15px;
      ////display: none;
      //
      //z-index: 999;
    }

    .main-nav__site {
      margin: -20% 0 90px 0;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;

      font-size: 36px;
      line-height: 40px;
      font-weight: 700;

      li {
        margin: 10px 0;

        a:hover,
        a:focus {
          color: #F0B90B;
        }
      }
    }

    .main-nav__user {
      margin: 0;
      flex-direction: column;
      align-items: center;
      width: 100%;

      li {
        &:nth-child(2) {
          margin-left: 0;
        }

        button, a {
          margin-bottom: 15px;
          width: 100%;
          min-width: 260px;
          height: 50px;
          font-size: 36px;
          line-height: 44px;
        }
      }
    }
  }

  .main-nav__lang-li {
    padding: 0;
    & > span {
      &::after {
        margin: 0 0 4px 10px;
        width: 12px;
        height: 12px;

        border-right: 3px solid #F0A20B;
        border-bottom: 3px solid #F0A20B;
      }
    }

    &.show {
      & > span {
        &::after {
          margin: 0 0 7px 10px;
        }
      }
    }
  }

  .main-nav__lang-list {
    & > li {
      justify-content: left;

      &::after {
        content: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    .main-nav__site {
      font-size: 28px;
      line-height: 30px;

      li {
        margin: 10px 0;
      }
    }

    .main-nav__user {
      li {
        button, a {
          font-size: 28px;
          line-height: 30px;
        }
      }
    }
  }

  .main-nav__lang-li {
    padding: 0;
    & > span {
      &::after {
        margin: 0 0 3px 10px;
        width: 10px;
        height: 10px;

        border-right: 3px solid #F0A20B;
        border-bottom: 3px solid #F0A20B;
      }
    }

    &.show {
      & > span {
        &::after {
          margin: 0 0 6px 10px;
        }
      }
    }
  }

  .main-nav__lang-list {
    & > li {
      justify-content: left;

      &::after {
        margin: 0 0 0 10px;
        width: 10px;
        height: 10px;

        border-right: 3px solid #F0A20B;
        border-bottom: 3px solid #F0A20B;
      }
    }
  }
}

@media (max-width: 550px) {
  .header {
    .main-nav__wrapper {
      width: 70%;
    }
  }
}

@media (max-width: 400px) {
  .header {
    .main-nav__site {
      font-size: 24px;
      line-height: 28px;

      li {
        margin: 10px 0;
      }
    }

    .main-nav__user {
      li {
        button, a {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }

  .main-nav__lang-li {
    padding: 0;
    & > span {
      &::after {
        margin: 0 0 3px 10px;
        width: 8px;
        height: 8px;

        border-right: 3px solid #F0A20B;
        border-bottom: 3px solid #F0A20B;
      }
    }

    &.show {
      & > span {
        &::after {
          margin: 0 0 5px 10px;
        }
      }
    }
  }

  .main-nav__lang-list {
    & > li {
      justify-content: left;

      &::after {
        margin: 0 0 0 10px;
        width: 8px;
        height: 8px;

        border-right: 3px solid #F0A20B;
        border-bottom: 3px solid #F0A20B;
      }
    }
  }
}
