.solutions {
  position: relative;
  padding: 1px 0;
  width: 100%;
}

.solutions__id-link {
  position: absolute;
  top: -70px;
}

.solutions__fon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: -1;
}

.solutions__block {
  position: relative;
  margin: 85px 0;
  padding: 45px;
  width: 100%;

  background: rgba(33, 37, 46, 0.7);
  border-radius: 16px;

  overflow: hidden;
}

.solutions__block-img {
  position: absolute;
  right: 20px;
  bottom: 0;

  z-index: 1;
}

.solutions__block-text {
  position: relative;

  width: 50%;
}

.solutions__block-title {
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
}

.solutions__block-description {
  margin: 15px 0 0 0;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

@media (max-width: 900px) {
  .solutions__block-text {
    width: 100%;
  }

  .solutions__block-title {
    font-size: 28px;
    line-height: 120%;
  }

  .solutions__block-img {
    //width: 100%;
    position: relative;
    bottom: -52px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 600px) {
  .solutions__block {
    margin: 50px 0;
  }

  .solutions__block-img {
    width: 100%;
  }
}
