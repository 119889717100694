.exchange {
  position: relative;
  width: 100%;

  .slick-list {
    width: 100%;
  }
}

.exchange__id-link {
  position: absolute;
  top: -70px;
}

.exchange__bg-img-left {
  position: absolute;
  left: 40px;
  top: 20%;

  width: 63px;

  z-index: -1;
}

.exchange__bg-img-right {
  position: absolute;
  right: 50px;
  top: -5%;

  width: 160px;

  z-index: 1;
}

.exchange__wrapper {
  position: relative;

  padding-top: 20px;
  padding-bottom: 50px;

  z-index: 2;
}

.exchange__title {
  margin: 20px 0 10px;
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
  text-align: center;

  span {
    color: #F0B90B;
  }
}

.exchange__description {
  margin: 0;
  text-align: center;
}

.exchange__list {
  margin: 30px 0;
  padding: 0;
  list-style: none;

  display: flex;
  justify-content: space-between;
}

.exchange__item {
  margin: 0 25px;
  width: calc(100% - 50px);
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  border-radius: 12px;
}

.exchange__item-a {
  color: inherit;
  text-decoration: none;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.exchange__item-img-wrapper {
  height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.exchange__item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exchange__item-info {
  margin: 0;
  padding: 25px;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  color: #160E33;
  background: #FFFFFF;
}

.exchange__item-title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.exchange__item-p {
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  opacity: 0.6;
}

.exchange__item-button {
  margin-top: auto;
  width: 210px;
  height: 55px;

  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;

  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  background: #F6D658;
  border-radius: 20px;
  border: none;

  &:hover {
    background: #F0B90B;
  }
}

.exchange {
  & .slick-track {
    display: flex !important;
  }

  & .slick-slide {
    height: inherit !important;
    //width: 380px;
    min-width: 200px !important;

    & > div {
      height: 100%;

      &:focus,
      &:hover {
        outline: none !important;
      }
    }
  }

  & .slick-prev,
  & .slick-next {
    top: 50%;
    left: -50px;
    display: block;
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translateY(-70%);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;

      border-bottom: 2px solid #FFFFFF;
      border-left: 2px solid #FFFFFF;
      transform: translate(-40%, -50%) rotate(45deg);
      transition: 0.3s;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      &:before {
        border-color: #F0B90B;
      }
    }
  }

  & .slick-next {
    left: auto;
    right: -50px;

    &:before {
      content: '';
      transform: translate(-60%, -50%) rotate(-135deg);
    }
  }
}

@media (max-width: 1300px) {
  .exchange__wrapper {
    width: 1000px;
  }

  .exchange__item {
    margin: 0 15px;
    width: calc(100% - 30px);
  }

  .exchange {
    & .slick-prev,
    & .slick-next {
      width: 50px;
      height: 50px;

      &:before {
        width: 8px;
        height: 8px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .exchange__wrapper {
    width: 850px;
  }
}

@media (max-width: 1024px) {
  .exchange__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 900px) {
  .exchange__bg-img-left {
    left: 20px;
    top: 12%;
  }

  .exchange__bg-img-right {
    right: 0px;
    top: -12%;
  }

  .exchange__wrapper {
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
  }

  .exchange__item-img-wrapper {
    height: 200px;
  }

  .exchange__item-info {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .exchange__bg-img-right {
    top: -9%;
    width: 120px;
  }

  .exchange__title {
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
  }

  .exchange__item {
    margin: 0 15px;
    width: calc(100% - 30px);
  }

  .exchange__item-title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .exchange__item-p {
    margin: 10px 0 15px;
    font-size: 16px;
    line-height: 120%;
  }

  .exchange__item-button {
    width: 180px;
    height: 45px;

    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 550px) {
  .exchange__bg-img-right {
    right: 0;
    top: -4%;
    width: 80px;
  }
}

@media (max-width: 450px) {
  .exchange__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
  }

  .exchange__item {
    margin: 0;
    width: 100%;
  }

  .exchange {
    & .slick-prev,
    & .slick-next {
      width: 40px;
      height: 40px;

      &:before {
        width: 8px;
        height: 8px;
      }
    }
  }
}
