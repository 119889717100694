.banner {
  width: 100%;
  //height: 100vh;
  height: 450px;
  position: relative;
  background: #0C0E11;
  overflow: hidden;
}

.banner__wrapper {
  //padding-top: 80px;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.banner__video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  object-fit: cover;

  z-index: 1;

  &--fon {
    position: absolute;
    top: 75%;
    left: 60%;
    transform: translate(-50%, -50%);
    //width: 62%;
    //height: 120%;
    object-fit: cover;

    filter: grayscale(1);

    z-index: 1;
  }
}

.banner__info-wrapper {
  margin-top: 40px;
}

.banner__info {
  width: 50%;

  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  p {
    margin: 0;
  }

  .color {
    color: #F0B90B;
  }
}

@media (max-width: 880px) {
  .banner__video {
    &--fon {
      filter: grayscale(1) opacity(0.7);
    }
  }

  .banner__info {
    position: relative;
    width: 70%;

    z-index: 2;
  }
}

@media (max-width: 768px) {
  .banner__info {
    position: relative;
    width: 80%;

    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 550px) {
  .banner {
    height: 380px;
  }

  .banner__info {
    width: 90%;
  }
}
