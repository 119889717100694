.individual {
  position: relative;
  padding: 1px 0;
  width: 100%;
}

.individual__id-link {
  position: absolute;
  top: -70px;
}

.individual__fon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: -1;
}

.individual__block {
  position: relative;
  margin: 85px 0;
  padding: 45px;
  width: 100%;

  display: flex;
  align-items: center;

  background: rgba(33, 37, 46, 0.7);
  border-radius: 16px;

  overflow: hidden;
}

.individual__block-img {
  margin-right: 50px;
  width: 125px;
  height: 125px;
}

.individual__block-text {
  position: relative;
  width: calc(100% - 175px);
}

.individual__block-title {
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
}

.individual__block-description {
  margin: 15px 0 0 0;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

@media (max-width: 900px) {
  .individual__block-img {
    margin-right: 50px;
    width: 100px;
    height: 100px;
  }

  .individual__block-text {
    position: relative;
    width: calc(100% - 150px);
  }

  .individual__block-title {
    font-size: 28px;
    line-height: 120%;
  }
}

@media (max-width: 600px) {
  .individual__block {
    margin: 50px 0;
    padding: 45px 30px;
  }

  .individual__block {
    flex-direction: column;
  }

  .individual__block-text {
    width: 100%;
  }

  .individual__block-img {
    margin: 0 auto 20px;
  }
}
