.contact {
  width: 100%;

  background: #0C0E11;
}

.contact__wrapper {
  padding-top: 25px;
  padding-bottom: 35px;
}

.contact__info {
  display: flex;
  justify-content: space-between;
}

.contact__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;

  //width: 33%;
  width: 100%;
}

.contact__item {
  margin-bottom: 20px;
  margin-right: 100px;
}

.contact__field-name {
  margin: 10px 0 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  //text-transform: uppercase;
}

.contact__field-info {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #A0A0A0;

  & span {
    color: #F6D658;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }
}

.contact__form {
  display: flex;
  justify-content: space-between;
  width: 66%;
}

.contact__inputs-wrapper {
  width: 49%;
}

.contact__input-container {
  position: relative;
  //margin: 10px 0;
}

.contact__textarea,
.contact__input {
  box-sizing: border-box;
  margin: 6px 0;
  padding: 0 15px 0 45px;
  //width: 366px;
  width: 100%;
  height: 45px;

  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;

  background: transparent;
  border: 1px solid #313131;

  transition: 0.3s;
  outline: none;

  &:focus {
    border: 1px solid #F0B90B;

    & + .contact__input-label svg path {
      fill: #F0B90B;
    }
  }
}

.contact__textarea {
  padding: 15px 15px 15px 45px;
  height: 102px;
}

.contact__input-label {
  position: absolute;
  top: 14px;
  left: 15px;

  & svg path {
    transition: 0.3s;
  }
}

.contact__btn {
  box-sizing: border-box;
  display: inline-block;
  margin-top: -1px;
  padding: 9px 20px;
  height: 44px;
  text-decoration: none;
  //text-transform: uppercase;
  font-weight: bold;
  color: #000000;
  background: #F6D658;
  border: none;
  transition: 0.3s;

  &:hover {
    background: #F0B90B;
  }
}

@media (max-width: 940px) {
  .contact__info {
    flex-direction: column;
  }

  .contact__list {
    width: 100%;
  }

  .contact__form {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contact__list {
    flex-direction: column;
  }

  .contact__input-label {
    top: 24px;
  }
}

@media (max-width: 650px) {
  .contact__form {
    flex-direction: column;
  }

  .contact__inputs-wrapper {
    width: 100%;
  }
}
