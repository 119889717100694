.select__wrapper {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
}

.select {
  //cursor: pointer;
  position: relative;
  width: 100%;
  //height: 40px;

  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;

  background: #0C0E11;
  box-shadow: 0px 6.4px 6.4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 16px;

  user-select: none;
  overflow: hidden;

  &.opened {
    .select__selected-arrow {
      top: 28px;
      transform: rotate(225deg);
    }
  }
}

.select__selected-item {
  padding: 0 25px;
  width: 100%;
  height: 60px;

  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    height: 60%;
  }
}

.select__selected-arrow {
  position: absolute;
  top: 24px;
  right: 40px;

  width: 10px;
  height: 10px;
  border-right: 1px solid #A0A0A0;
  border-bottom: 1px solid #A0A0A0;

  transform: rotate(45deg);
  transition: 0.3s;
}

.select__list {
  position: relative;

  margin: 0;
  padding: 0;
  width: 100%;

  list-style: none;
  background: #131418;

  z-index: 10;

  li {
    position: relative;
    padding: 0 25px;
    height: 58px;

    display: flex;
    align-items: center;

    border-top: 1px solid #24252D;

    img {
      margin-right: 10px;
      height: 60%;
    }

    &:hover,
    &:focus {
      background: #24252D;
    }
  }
}

.select__item-img {
  margin-right: 10px;
  height: 80%;
}

@media (max-width: 768px) {
  .select {
    &.opened {
      .select__selected-arrow {
        top: 24px;
      }
    }
  }

  .select__wrapper {
    height: 50px;
  }

  .select__selected-item {
    height: 50px;
  }

  .select__selected-arrow {
    top: 18px;
  }

  .select__list {
    li {
      height: 50px;
    }
  }
}
