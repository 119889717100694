.steps {
  position: relative;
  width: 100%;
}

.steps__wrapper {
  position: relative;

  padding-top: 30px;
  padding-bottom: 30px;

  z-index: 2;
}

.steps__bg-img-right {
  position: absolute;
  right: 50px;
  bottom: -10%;

  width: 160px;

  z-index: 1;
}

.steps__block-title {
  margin: 20px;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
}

.steps__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
}

.steps__item {
  padding: 25px 20px;

  display: flex;
  flex-direction: column;

  border-radius: 10px;

  &:hover {
    background: #2C3138;
  }
}

.steps__item-img {
  width: 64px;
  height: 64px;
}

.steps__item-title {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.steps__item-text {
  margin: 10px 0 20px;
  font-size: 14px;
  line-height: 17px;
}

.steps__item-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  margin-top: auto;
  width: 175px;
  height: 40px;

  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: #181A1F;

  background: #F6D658;
  border-radius: 5px;

  &:hover {
    background: #F0B90B;
  }
}

@media (max-width: 1024px) {
  .steps__bg-img-right {
    right: 10px;
    bottom: -10%;
  }

  .steps__list {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .steps__item {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .steps__block-title {
    font-size: 28px;
    line-height: 30px;
  }
}

@media (max-width: 480px) {
  .steps__bg-img-right {
    right: 0px;
    bottom: -3%;

    width: 100px;
  }

  .steps__wrapper {
    padding-bottom: 0;
  }

  .steps__list {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .steps__item {
    width: 100%;

    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .steps__item-title {
    margin-left: 20px;
    width: 50%;
  }
}