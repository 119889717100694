.cooperation {
  position: relative;
  padding: 1px 0;
  width: 100%;
}

.cooperation__id-link {
  position: absolute;
  top: -70px;
}

.cooperation__fon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: -1;
}

.cooperation__block {
  position: relative;
  margin: 85px 0;
  padding: 45px;
  width: 100%;

  background: #21252E;
  border-radius: 16px;

  overflow: hidden;
}

.cooperation__block-img-left {
  position: absolute;
  left: 20px;
  top: 0;

  z-index: 1;
}

.cooperation__block-img-right {
  position: absolute;
  right: 20px;
  bottom: 0;

  z-index: 1;
}

.cooperation__block-text {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 2;

  div {
    width: 40%;
  }
}

.cooperation__block-title {
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
}

.cooperation__block-description {
  margin: 15px 0 0 0;
  padding-right: 15px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.cooperation__wrapper-input {
  position: relative;
  width: 30%;
}

.cooperation__block-input {
  padding: 5px 0;
  width: 95%;

  font-family: inherit;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: inherit;

  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  &::placeholder {
    font-family: inherit;
    color: inherit;
  }
}

.cooperation__block-button {
  width: 182px;
  height: 55px;

  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  line-height: 150%;

  background: #FFFFFF;
  border-radius: 32px;
  border: none;

  transition: 0.2s;

  &:hover {
    background: #c8c8c8;
  }
}

.cooperation__form-result {
  position: absolute;

  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  color: #FFFFFF;
  opacity: 0.4;
}

@media (max-width: 900px) {
  .cooperation__block-text {
    flex-direction: row;
    flex-wrap: wrap;

    div {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .cooperation__wrapper-input {
    width: 60% !important;
  }

  .cooperation__block-title {
    font-size: 28px;
    line-height: 120%;
  }
}

@media (max-width: 600px) {
  .cooperation__block {
    margin: 50px 0;
  }

  .cooperation__wrapper-input {
    width: 100% !important;
    margin-bottom: 35px !important;
  }
}
