.faq {
  position: relative;
  width: 100%;
}

.faq__bg-img-left {
  position: absolute;
  left: 100px;
  top: 5%;

  width: 95px;

  z-index: 1;
}

.faq__bg-img-right {
  position: absolute;
  right: 70px;
  bottom: -7%;

  width: 90px;

  z-index: 1;
}

.faq__id-link {
  position: absolute;
  top: -70px;
}

.faq__wrapper {
  position: relative;
  padding-top: 25px;
  padding-bottom: 35px;
  text-align: center;

  z-index: 2;
}

.faq__title {
  margin: 15px 0;
}

.faq__description {
  margin: 0 0 30px 0;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
}

.faq__list {
  margin: 0 10%;

  text-align: left;

  .Collapsible:first-child {
    .Collapsible__trigger {
      border-top: none;
    }
  }
}

.Collapsible {

}

.Collapsible__trigger {
  position: relative;
  display: block;

  margin: 10px 0;
  padding: 16px 60px 16px 20px;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;

  background: #0C0E11;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &:before,
  &:after {
    content: '';

    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);

    width: 24px;
    height: 4px;
    background-color: #F6D658;
    border-radius: 10px;

    transition: 0.3s;
  }

  &:after {
    transform: translateY(-50%) rotate(90deg);
  }

  &.is-open {
    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(135deg);
    }
  }
}

.Collapsible__contentInner {
  padding: 0 25px;

  p {
    margin: 15px 0;

    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
  }
}


@media (max-width: 768px) {
  .faq__bg-img-left {
    left: 10px;
    top: 0%;
  }

  .faq__bg-img-right {
    position: absolute;
    right: 30px;
  }

  .faq__list {
    margin: 0;
  }

  .faq__description {
    font-size: 18px;
    line-height: 20px;
  }
}

@media (max-width: 550px) {
  .faq__bg-img-left {
    display: none;
  }

  .faq__list {
    margin: 0;
  }

  .Collapsible__trigger {
    font-size: 14px;
    line-height: 18px;
  }

  .Collapsible__contentInner p {
    font-size: 14px;
    line-height: 18px;
  }
}