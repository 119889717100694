.advantages {
  position: relative;
  width: 100%;
}

.advantages__id-link {
  position: absolute;
  top: -70px;
}

.advantages__bg-img-left {
  position: absolute;
  left: 10px;
  top: -5%;

  width: 160px;

  z-index: -1;
}

.advantages__bg-img-right {
  position: absolute;
  right: 50px;
  top: 8%;

  width: 95px;

  z-index: -1;
}

.advantages__title {
  margin: 20px;
  font-weight: bold;
  font-size: 64px;
  line-height: 76px;
  text-align: center;
}

.advantages__description {
  margin: 0;
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
}

.advantages__list {
  margin: 30px 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.advantages__item {
  margin: 10px 0 30px;
  width: 30%;

  display: flex;
  flex-wrap: wrap;
}

.advantages__item-img {
  margin-right: 30px;
  width: 50px;
  height: 50px;

  align-self: center;
}

.advantages__item-title {
  margin: 0;
  width: calc(100% - 80px);

  font-weight: 500;
  font-size: 28px;
  line-height: 36px;

  align-self: center;
}

.advantages__item-text {
  margin: 15px 0 0 0;
  width: 100%;

  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 1024px) {
  .advantages__title {
    margin: 15px auto;
    width: 60%;
    font-size: 52px;
    line-height: 58px;
  }

  .advantages__description {
    margin: 15px auto;
    width: 60%;
    font-size: 20px;
    line-height: 24px;
  }

  .advantages__item {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .advantages__bg-img-left {
    left: 0px;
    top: -5%;
    width: 100px;
  }

  .advantages__bg-img-right {
    right: 0;
    top: 12%;
    width: 80px;
  }

  .advantages__title {
    margin: 20px auto 5px;
    width: 70%;
    font-size: 40px;
    line-height: 50px;
  }

  .advantages__description {
    margin: 10px auto 15px;
    width: 70%;
    font-size: 20px;
    line-height: 24px;
  }

  .advantages__item {
    width: 100%;
  }

  .advantages__item-img {
    margin-right: 10px;
    width: 40px;
    height: 40px;

    align-self: center;
  }

  .advantages__item-title {
    margin: 0;
    width: calc(100% - 50px);

    font-weight: 500;
    font-size: 22px;
    line-height: 28px;

    align-self: center;
  }
}

@media (max-width: 480px) {
  .advantages__bg-img-left {
    top: -6%;
  }

  .advantages__title {
    margin: 20px auto 5px;
    padding: 0 40px;
    width: 100%;
    font-size: 34px;
    line-height: 40px;
  }

  .advantages__description {
    margin: 10px auto 15px;
    width: 70%;
    font-size: 18px;
    line-height: 22px;
  }

  .advantages__item-title {
    margin: 0;
    width: calc(100% - 50px);

    font-size: 20px;
    line-height: 24px;
  }
}
