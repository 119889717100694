@font-face {
  font-family: 'Rubik';
  src: url('./ui/assets/fonts/Rubik-Light.eot');
  src: url('./ui/assets/fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
  url('./ui/assets/fonts/Rubik-Light.ttf') format('truetype'),
  url('./ui/assets/fonts/Rubik-Light.woff') format('woff'),
  url('./ui/assets/fonts/Rubik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./ui/assets/fonts/Rubik-Regular.eot');
  src: url('./ui/assets/fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('./ui/assets/fonts/Rubik-Regular.ttf') format('truetype'),
  url('./ui/assets/fonts/Rubik-Regular.woff') format('woff'),
  url('./ui/assets/fonts/Rubik-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./ui/assets/fonts/Rubik-Medium.eot');
  src: url('./ui/assets/fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('./ui/assets/fonts/Rubik-Medium.ttf') format('truetype'),
  url('./ui/assets/fonts/Rubik-Medium.woff') format('woff'),
  url('./ui/assets/fonts/Rubik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./ui/assets/fonts/Rubik-SemiBold.eot');
  src: url('./ui/assets/fonts/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./ui/assets/fonts/Rubik-SemiBold.ttf') format('truetype'),
  url('./ui/assets/fonts/Rubik-SemiBold.woff') format('woff'),
  url('./ui/assets/fonts/Rubik-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./ui/assets/fonts/Rubik-Bold.eot');
  src: url('./ui/assets/fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
  url('./ui/assets/fonts/Rubik-Bold.ttf') format('truetype'),
  url('./ui/assets/fonts/Rubik-Bold.woff') format('woff'),
  url('./ui/assets/fonts/Rubik-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  //width: 100%;
  //height: 100%;

  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  //text-align: justify;
  //letter-spacing: -0.015em;

  color: #FFFFFF;
  background: #181A1F;

  overflow-x: hidden;
  //scroll-behavior: smooth;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  //height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: inherit;
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
}

a {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
}

input, textarea {
  font-family: inherit;
  outline: none !important;
  background-clip: padding-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  width: 1200px;
  height: 100%;
}

.title {
  margin: 0;
  font-family: inherit;
  font-weight: 700;
  font-size: 64px;
  line-height: 76px;
}

@keyframes circle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-circle {
  transform-origin: 50% 50%;
  animation-duration: 5s;
  animation-name: circle;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.btn-main {
  box-sizing: border-box;
  margin-top: 22px;
  padding: 0 20px;
  width: 255px;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0C0E11;

  background: #F6D658;
  border: none;
  border-radius: 32px;

  transition: 0.2s;

  svg {
    margin-left: auto;
  }

  &:focus,
  &:hover {
    background: #F0B90B;
  }
}

.input {
  margin-bottom: 20px;
  padding: 22px 35px;
  height: 68px;

  display: flex;
  align-items: center;

  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
  color: #FFFFFF;

  background: #0C0E11;
  box-shadow: 0px 6.4px 6.4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 16px;

  &::placeholder {
    color: #FFFFFF;
  }

  &--error {
    border: 2px solid #F0B90B;
  }

  //&:invalid {
  //  border: 2px solid #F0B90B;
  //}
}

@media (max-width: 1250px) {
  .wrapper {
    width: 1000px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .title {
    font-size: 50px;
    line-height: 64px;
  }

  .btn-main {
    height: 60px;
  }

  .input {
    margin-bottom: 20px;
    padding: 15px 25px;
    height: 60px;

    font-size: 18px;
  }
}

@media (max-width: 768px) {
  html, body {
    font-size: 14px;
    line-height: 20px;
  }

  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .title {
    font-size: 40px;
    line-height: 50px;
  }

  .btn-main {
    height: 54px;
  }

  .input {
    height: 54px;
  }
}

@media (max-width: 550px) {
  .btn-main {
    width: 240px;
    height: 54px;

    font-size: 16px;
    line-height: 20px;
  }
}
